
import React, { FunctionComponent } from 'react';
import 'react-toastify/dist/ReactToastify.css';
import './TopMenu.css';


const TopMenu: FunctionComponent = () =>
{
  return (
    <div className='TopMenu'>
      <span>ShrinkMe</span>
    </div>
  );
};

export default TopMenu;
